import { as_json, number_to_currency } from '@/lib/helpers'
import _ from 'underscore'
import { DateTime } from 'luxon'

AgencyApiV1 = require('@/services/agency_api_v1')
agency_api_client = new AgencyApiV1.ApiClient()
agency_api = new AgencyApiV1.DefaultApi(agency_api_client)

default_state = -> {
  client_config: null,
  collection_date: '',
  credit_in_cents: 0,
  items: [],
  loading: false,
  out_of_stock: false,
  total_error: '',
  order_id: null,
  payment_provider: null
  requires_bags: false,
  requires_shipping: false,
  shipping_provider: null
  shipping_address: {},
  shipping_cost_in_cents: 0,
  show_outro: false,
  subtotal_in_cents: 0,
  supplier: null,
  total_in_cents: 0,
  transaction: {},
  updated: false,
  validated_at: null
}

state = default_state()

getters = {
  has_valid_config: (state, getters, rstate, rgetters) ->
    return false if not getters.valid_agency_config
    return false if not rstate.application.features.agency and not getters.valid_market_config
    true

  valid_agency_config: (state) ->
    state.items.length >= 1 && state.supplier != null

  valid_market_config: (state) ->
    state.client_config?.shipping?.length > 0

  supplier: (state) ->
    if !!state.supplier?.id then state.supplier else null

  requires_online_payment: (state) ->
    state.payment_provider.toLowerCase() in ['paygate', 'shop2shop', 'moya', 'yoco']

  requires_offine_payment: (state) ->
    state.payment_provider.toLowerCase() in ['offline_eft']

  subtotal_in_cents: (state) ->
    total_price_in_cents_for(state.items)

  total_in_cents: (state) ->
    state.total_in_cents

  formatted_total: (state, getters, rstate, rgetters) ->
    number_to_currency({
      symbol: rgetters['application/currency'],
      cents: state.total_in_cents
    })

  is_credit_checkout: (state, getters) ->
    parseFloat(getters.subtotal_in_cents) <= parseFloat(getters.credit_in_cents)

  payment_options: (state, g, rS, rootGetters) ->
    dispatch = if !state.requires_shipping then 'COLLECT' else state.shipping_provider.toUpperCase()
    if rootGetters['application/is_agency']
      options = rootGetters['application/payment_options'](dispatch)
      if 'DEBTORCARD' in options && !rootGetters['account/find_active_debtorcard'](state.supplier.id)
        options = options.filter (opt) -> opt != 'DEBTORCARD'
      options
    else
      shipping_option = state.client_config.shipping.find (option) -> option.name == dispatch
      shipping_option.payment_options

  register_order_payload: (state, getters, rs, rootGetters) ->
    {
      cart: {
        items: as_json(state.items),
        provider: state.payment_provider,
        supplier_id: state.supplier.id,
        shipping_address: state.shipping_address,
        requires_shipping: state.requires_shipping,
        requires_bags: state.requires_bags,
        shipping_provider: state.shipping_provider,
        collection_date: state.collection_date,
        order_id: rootGetters['orders/current_id'],
        shopit: !rootGetters['application/is_agency'],
        stokvel: getters['stokvel'],
        is_demo: rootGetters['account/as_json'].demo,
        credit_in_cents: state.credit_in_cents
      },
      user: rootGetters['account/as_payload']
    }

  valid_collection_date: (state) ->
    date = DateTime.fromISO(state.collection_date)
    now = DateTime.local()
    date.diff(now, 'minutes').toObject().minutes >= 45

  valid_delivery_date: (state) ->
    date = DateTime.fromISO(state.collection_date)
    now = DateTime.local()
    days_difference = date.startOf('day').diff(now.startOf('day'), 'days').toObject().days
    return true if days_difference > 0
    return false if days_difference < 0
    now.hour < date.hour

  validate_payload: (state, g, rS, rootGetters) ->
    {
      cart: {
        items: as_json(state.items),
        requires_shipping: state.requires_shipping,
        shipping_provider: state.shipping_provider
      },
      user: rootGetters['account/as_payload']
    }

  shipping_options: (state, getters, rstate, rgetters) ->
    return (shipping.name for shipping in state.client_config.shipping) if not rstate.application.features.agency
    (shipping.name for shipping in rstate.application.features.shipping)

  stokvel: (state, getters, rstate, rgetters) ->
    return false if not rstate.application.features.stokvel
    stokvel_items = state.items.filter (i) -> i.offer.stokvel
    return false if stokvel_items.length < 5
    stokvel_subtotal = total_price_in_cents_for(stokvel_items)
    stokvel_subtotal >= 500000
}

actions = {
  ## Market checkout requires an Agency checkout configuration
  fetch_client_config: ({ commit, state }) ->
    commit('loading', true)
    if !!state.supplier.api_endpoint
      agency_api.apiClient.basePath = state.supplier.api_endpoint
      agency_api.fetchClientConfig(state.supplier.id)
      .then (config) ->
        commit('set_client_config', _.extend({}, config))
      .catch (error) ->
        console.error 'fetch_config.catch:', error
      .finally ->
        commit('loading', false)
    else
      commit('loading', false)
      console.error 'fetch_client_config: api_endpoint undefined'

  ## Validate offers price and stock
  validate: ({ commit, state, getters, rootGetters }) ->
    commit('discard_out_of_stock_items')
    commit('loading', true)
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.validate { body: getters['validate_payload'] }
    .then (response) ->
      commit('setup', response.validation)
      commit('validated_at', new Date())
      commit('loading', false)
      response.validation
    .catch (error) ->
      commit('loading', false)
      commit('validated_at', null)
      console.error 'agency_api.validate:error', error

  register_order: ({ commit, state, getters, rootGetters }) ->
    commit('loading', true)
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.registerOrder { body: getters['register_order_payload'] }
    .then (response) ->
      commit('set_order_id', response.order.id)
      commit('orders/set_current', response.order, { root: true })
      commit('loading', false)
      response.order
    .catch (error) ->
      commit('orders/flush_current', null, { root: true })
      commit('loading', false)
      console.error 'agency_api.registerOrder.catch:', error

  register_transaction: ({ commit, rootGetters, state }) ->
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.registerTransaction state.order_id
    .then (response) -> response
    .catch (response) ->
      console.error 'agency_api.registerTransaction.catch:', response

  finalize_order: ({ commit, state, rootGetters }) ->
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.prepareOrder state.order_id
    .then (response) ->
      commit('loading', false)
    .catch (response) ->
      console.error 'agency_api.finalize_order.catch:', response
      commit('loading', false)

  pending_payment: ({ commit, state, rootGetters }) ->
    new Promise((resolve, reject) -> resolve())
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.offlinePayment state.order_id
    .then (response) ->
      commit('loading', false)
    .catch (response) ->
      console.error 'agency_api.pending_payment.catch:', response
      commit('loading', false)

  fetch_transaction: ({ state, rootGetters }, params) ->
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.fetchTransaction params.id
    .then (response) -> response
    .catch (error) ->
      console.error 'agency_api.fetchTransaction catch', error

  fetch_timeslot_options: ({ commit, state, getters, rootGetters }) ->
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters['application/api_host']
    agency_api.fetchSupplierTimeslots(state.supplier.id, {
      requires_shipping: state.requires_shipping,
      stokvel: getters['stokvel'],
      provider: state.provider
    })
    .then (response) -> response
    .catch (error) ->
      console.error 'agency_api.fetchTimeslotOptions catch', error

  restore: ({ commit }) ->
    local_checkout = JSON.parse(localStorage.getItem('checkout-v1'))
    commit('setup', local_checkout) if local_checkout

  reset: ({ commit, dispatch }) ->
    commit('reset')
    commit('cart/clear', null, { root: true })
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  reset: (state) ->
    Object.assign(state, _.omit(default_state(), 'show_outro'))
    persist(state)

  # comparison = { items, supplier }
  setup: (state, comparison) ->
    state[k] = v for k, v of comparison
    persist(state)

  set_client_config: (state, config) ->
    state.client_config = config
    persist(state)

  set_requires_shipping: (state, shipping) ->
    state.requires_shipping = shipping
    state.payment_provider = null
    state.shipping_address = {} unless shipping
    persist(state)

  set_shipping_provider: (state, provider) ->
    state.shipping_provider = provider
    persist(state)

  set_shipping_address: (state, address) ->
    state.shipping_address = address
    persist(state)

  set_collection_date: (state, datetime) ->
    state.collection_date = datetime

  set_requires_bags: (state, value) ->
    state.requires_bags = value
    persist(state)

  set_order_id: (state, uuid) ->
    state.order_id = uuid
    persist(state)

  set_payment_provider: (state, provider) ->
    state.payment_provider = provider

  show_outro: (state, boolean) ->
    state.show_outro = boolean
    persist(state)

  discard_out_of_stock_items: (state) ->
    state.items = state.items.filter (item) -> !item.offer.out_of_stock_at
    persist(state)

  validated_at: (state, timestamp) ->
    state.validated_at = timestamp
}

persist = (state) ->
  localStorage.setItem('checkout-v1', JSON.stringify(_.omit(state, 'loading')))

total_price_in_cents_for = (items) ->
  _.reduce(items, sum_of_item_totals, 0)

sum_of_item_totals = (memo, value, index, list) ->
  memo + parseFloat(value.offer.price_in_cents) * Number(value.qty)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
