
import OrderShippingStatusPill from '@/components/order_shipping_status_pill'

export default {
  components: { OrderShippingStatusPill },

  computed: {
    account: ->
      @$store.state.account.current

    open_orders: ->
      @$store.state.orders.open.filter (order) ->
        order.shipping_status in ['stock_short', 'pending_payment']
  }

  data: -> {
    show_stock_short: false,
    open: false
  }

  watch: {
    account: (account) ->
      @$store.dispatch('orders/fetch_open_orders') if @$store.getters['account/logged_in']

    open_orders: ->
      @open = @open_orders.length > 0
  }

  created: ->
    @$store.dispatch('orders/fetch_open_orders')

  methods: {
    on_view_click: (event) ->
      event.preventDefault()
      @$router.push(event.currentTarget.pathname)
      @open = false
  }
}
