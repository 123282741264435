
export default {
  props: {
    per_kg: { type: Boolean, default: false },
    per_kg_conversion_rate: { type: String, default: '1.0' },
    unit_label: { type: String, default: '' },
    value: { type: Number } # v-model
  }

  data: -> {
    user_facing_qty: 0
    minimum: if @per_kg then 0.25 else 1
    increment: if @per_kg then 0.05 else 1
    symbol: if @per_kg then @unit_label else ''
  }

  created: ->
    if @value == 0
      @user_facing_qty = @minimum
    else
      @user_facing_qty = if @per_kg then @convert_kg_to_symbol(@value) else Number(@value)
    @format()

  watch: {
    user_facing_qty: (v) ->
      output_qty = if @per_kg then @convert_symbol_to_kg(v) else v
      @$emit('input', output_qty)
  }

  methods: {
    convert_kg_to_symbol: (value) ->
      Number(value) / Number(@per_kg_conversion_rate)

    convert_symbol_to_kg: (value) ->
      Number(value) * Number(@per_kg_conversion_rate)

    decrease_qty: ->
      @user_facing_qty = (Number.parseFloat(@user_facing_qty) - @increment).toFixed(2) if @user_facing_qty > @minimum
      @format()

    increase_qty: ->
      @user_facing_qty = (Number.parseFloat(@user_facing_qty) + @increment).toFixed(2)
      @format()

    set_qty: (event) ->
      @qty = parseFloat(event.currentTarget.value)
      @format()

    format: ->
      q = Math.floor(Number(@user_facing_qty).toFixed(2) * 20)/20
      formatted = if @per_kg then q.toFixed(2) else q.toFixed()
      @user_facing_qty = formatted
  }
}
