import application from '@/application.yml'
import superagent from 'superagent'

default_state = ->
  application

actions = {
  fetch_config: ({ commit }) ->
    superagent.get("#{application.api_host}/shopfront_config")
    .then (response) ->
      return false if response.body.status is 'not_found'
      config = response.body.config.config
      commit('set_application_config', config)
      config
    .catch(console.error)
}

getters = {
  api_host: (state) ->
    state.api_host

  currency: (state) ->
    state.meta.currency_symbol || 'R'

  currency_iso: (state) ->
      state.meta.currency_iso || 'ZAR'

  country: (state) ->
    state.meta.country_iso2 || 'ZA'

  debtorcards_enabled: (state) ->
    if state.features?.shipping?
      for shipping_method in state.features.shipping
        if shipping_method.payment_options?
          return 'DEBTORCARD' in shipping_method.payment_options
      false
    else
      false

  default_position: (state) ->
    state.meta.default_position

  does_deliver: (state) ->
    state.features.shipping?.some (option) -> option.name in ['PICUP']

  name: (state) ->
    state.meta.title

  payment_options: (state, g, rs, rg) -> (shipping_option) ->
    shipping_option = state.features.shipping.find (option) -> option.name == shipping_option
    shipping_option.payment_options

  is_agency: (state) ->
    state.features.agency

  logo_image_url: (state) ->
    if state.features.demo_station and state.theme.logo_image_url
      state.theme.logo_image_url
    else
      require('@/assets/logo.png')

  icon_image_url: (state) ->
    if state.features.demo_station
      state.theme.icon_image_url
    else
      require('@/assets/icon.png')

  facebook_url: ->
    state.meta.facebook_url || false

  promotion_carousel: ->
    state.theme.promotion_carousel

  product_page_variant: ->
    variant = (state.theme.product_page_variant || '').toLowerCase()
    return variant if variant in ['', 'offers']
    ''
}

mutations = {
  read_localstore: (state) ->
    return if process.env in ['production', 'staging']
    # console.log 'READING LOCAL APPLICATION CONFIG', JSON.parse(localStorage.getItem('application-v1'))
    Object.assign(state, JSON.parse(localStorage.getItem('application-v1')))

  set_application_config: (state, config) ->
    Object.assign(state, config)
}

state = default_state()

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
