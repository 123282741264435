/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CartItems} from './CartItems';
import {RegisterOrderRequestCartShippingAddress} from './RegisterOrderRequestCartShippingAddress';

/**
 * The RegisterOrderRequestCart model module.
 * @module model/RegisterOrderRequestCart
 * @version v1
 */
export class RegisterOrderRequestCart {
  /**
   * Constructs a new <code>RegisterOrderRequestCart</code>.
   * @alias module:model/RegisterOrderRequestCart
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RegisterOrderRequestCart</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RegisterOrderRequestCart} obj Optional instance to populate.
   * @return {module:model/RegisterOrderRequestCart} The populated <code>RegisterOrderRequestCart</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RegisterOrderRequestCart();
      if (data.hasOwnProperty('items'))
        obj.items = CartItems.constructFromObject(data['items']);
      if (data.hasOwnProperty('order_id'))
        obj.order_id = ApiClient.convertToType(data['order_id'], 'String');
      if (data.hasOwnProperty('provider'))
        obj.provider = ApiClient.convertToType(data['provider'], 'String');
      if (data.hasOwnProperty('supplier_id'))
        obj.supplier_id = ApiClient.convertToType(data['supplier_id'], 'String');
      if (data.hasOwnProperty('shipping_address'))
        obj.shipping_address = RegisterOrderRequestCartShippingAddress.constructFromObject(data['shipping_address']);
      if (data.hasOwnProperty('shopit'))
        obj.shopit = ApiClient.convertToType(data['shopit'], 'Boolean');
      if (data.hasOwnProperty('requires_shipping'))
        obj.requires_shipping = ApiClient.convertToType(data['requires_shipping'], 'Boolean');
      if (data.hasOwnProperty('dispatch'))
        obj.dispatch = ApiClient.convertToType(data['dispatch'], 'String');
      if (data.hasOwnProperty('requires_bags'))
        obj.requires_bags = ApiClient.convertToType(data['requires_bags'], 'Boolean');
      if (data.hasOwnProperty('collection_date'))
        obj.collection_date = ApiClient.convertToType(data['collection_date'], 'String');
      if (data.hasOwnProperty('stokvel'))
        obj.stokvel = ApiClient.convertToType(data['stokvel'], 'Boolean');
      if (data.hasOwnProperty('is_demo'))
        obj.is_demo = ApiClient.convertToType(data['is_demo'], 'Boolean');
      if (data.hasOwnProperty('credit_in_cents'))
        obj.credit_in_cents = ApiClient.convertToType(data['credit_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {module:model/CartItems} items
 */
RegisterOrderRequestCart.prototype.items = undefined;

/**
 * @member {String} order_id
 */
RegisterOrderRequestCart.prototype.order_id = undefined;

/**
 * @member {String} provider
 */
RegisterOrderRequestCart.prototype.provider = undefined;

/**
 * @member {String} supplier_id
 */
RegisterOrderRequestCart.prototype.supplier_id = undefined;

/**
 * @member {module:model/RegisterOrderRequestCartShippingAddress} shipping_address
 */
RegisterOrderRequestCart.prototype.shipping_address = undefined;

/**
 * @member {Boolean} shopit
 */
RegisterOrderRequestCart.prototype.shopit = undefined;

/**
 * @member {Boolean} requires_shipping
 */
RegisterOrderRequestCart.prototype.requires_shipping = undefined;

/**
 * @member {String} dispatch
 */
RegisterOrderRequestCart.prototype.dispatch = undefined;

/**
 * @member {Boolean} requires_bags
 */
RegisterOrderRequestCart.prototype.requires_bags = undefined;

/**
 * @member {String} collection_date
 */
RegisterOrderRequestCart.prototype.collection_date = undefined;

/**
 * @member {Boolean} stokvel
 */
RegisterOrderRequestCart.prototype.stokvel = undefined;

/**
 * @member {Boolean} is_demo
 */
RegisterOrderRequestCart.prototype.is_demo = undefined;

/**
 * @member {Number} credit_in_cents
 */
RegisterOrderRequestCart.prototype.credit_in_cents = undefined;

