/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CorrelationJson} from './CorrelationJson';

/**
 * The SellableJson model module.
 * @module model/SellableJson
 * @version v1
 */
export class SellableJson {
  /**
   * Constructs a new <code>SellableJson</code>.
   * @alias module:model/SellableJson
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SellableJson</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SellableJson} obj Optional instance to populate.
   * @return {module:model/SellableJson} The populated <code>SellableJson</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SellableJson();
      if (data.hasOwnProperty('barcode'))
        obj.barcode = ApiClient.convertToType(data['barcode'], 'String');
      if (data.hasOwnProperty('brand'))
        obj.brand = ApiClient.convertToType(data['brand'], 'String');
      if (data.hasOwnProperty('brand_id'))
        obj.brand_id = ApiClient.convertToType(data['brand_id'], 'String');
      if (data.hasOwnProperty('catalog_paths'))
        obj.catalog_paths = ApiClient.convertToType(data['catalog_paths'], ['String']);
      if (data.hasOwnProperty('combo_items'))
        obj.combo_items = ApiClient.convertToType(data['combo_items'], [SellableJson]);
      if (data.hasOwnProperty('correlations'))
        obj.correlations = ApiClient.convertToType(data['correlations'], [CorrelationJson]);
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('image_url'))
        obj.image_url = ApiClient.convertToType(data['image_url'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('measurement'))
        obj.measurement = ApiClient.convertToType(data['measurement'], 'String');
      if (data.hasOwnProperty('pack'))
        obj.pack = ApiClient.convertToType(data['pack'], 'String');
      if (data.hasOwnProperty('pack_id'))
        obj.pack_id = ApiClient.convertToType(data['pack_id'], 'String');
      if (data.hasOwnProperty('product_id'))
        obj.product_id = ApiClient.convertToType(data['product_id'], 'String');
      if (data.hasOwnProperty('product'))
        obj.product = ApiClient.convertToType(data['product'], 'String');
      if (data.hasOwnProperty('unit'))
        obj.unit = ApiClient.convertToType(data['unit'], 'String');
      if (data.hasOwnProperty('unit_id'))
        obj.unit_id = ApiClient.convertToType(data['unit_id'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} barcode
 */
SellableJson.prototype.barcode = undefined;

/**
 * @member {String} brand
 */
SellableJson.prototype.brand = undefined;

/**
 * @member {String} brand_id
 */
SellableJson.prototype.brand_id = undefined;

/**
 * @member {Array.<String>} catalog_paths
 */
SellableJson.prototype.catalog_paths = undefined;

/**
 * @member {Array.<module:model/SellableJson>} combo_items
 */
SellableJson.prototype.combo_items = undefined;

/**
 * @member {Array.<module:model/CorrelationJson>} correlations
 */
SellableJson.prototype.correlations = undefined;

/**
 * @member {String} id
 */
SellableJson.prototype.id = undefined;

/**
 * @member {String} image_url
 */
SellableJson.prototype.image_url = undefined;

/**
 * @member {String} name
 */
SellableJson.prototype.name = undefined;

/**
 * @member {String} measurement
 */
SellableJson.prototype.measurement = undefined;

/**
 * @member {String} pack
 */
SellableJson.prototype.pack = undefined;

/**
 * @member {String} pack_id
 */
SellableJson.prototype.pack_id = undefined;

/**
 * @member {String} product_id
 */
SellableJson.prototype.product_id = undefined;

/**
 * @member {String} product
 */
SellableJson.prototype.product = undefined;

/**
 * @member {String} unit
 */
SellableJson.prototype.unit = undefined;

/**
 * @member {String} unit_id
 */
SellableJson.prototype.unit_id = undefined;

