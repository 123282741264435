import { api_for } from '@/services/helper'
import { as_json } from '@/lib/helpers'
import _ from 'underscore'

import Storage from '@/lib/storage'
store = new Storage({ key: 'orders-v1' })

# refactor to collection:
# index: []
# loading:
# next_page: 1
# incomplete: []
# incomplete_loading: false

# refactor to model:
# current:
# invalid_order_item: null
# loading: false
# modifications: []
# polling:
# remote_validation: {}
# timeout_id: null

defaults = -> {
  current: null
  error: null
  history: []
  invalid_order_item: null
  loading: false
  modifications: []
  open: []
  polling: false
  remote_validation: {}
  timeout_id: null
}

state = defaults()

getters = {
  current_id: (state) ->
    state.current?.id

  current_items: (state, getters) ->
    if state.current?.id
      items = for item in state.current?.order_items
        modification = state.modifications.find (mod) -> mod.order_item_id == item.id
        build_order_item(item, modification, state.current.stock_short)
      items.concat(getters.new_items).sort (a, b) ->
        if a.qty_ordered != a.qty then -1 else 1
    else []

  new_items: (state) ->
    (build_new_order_item(mod) for mod in state.modifications.filter (mod) -> mod.action == 'add')

  find_item: (state) -> (id) ->
    state.current.order_items.find (item) -> item.offer_id == id

  find_add_modification_by_offer_id: (state) -> (offer_id) ->
    state.modifications
      .filter((mod) -> mod.action == 'add')
      .find((mod) -> mod.item.offer.id == offer_id)

  find_modification: (state) -> (order_item_id) ->
    state.modifications.find (mod) -> mod.order_item_id == order_item_id

  offer_qty: (state, getters) -> (offer_id) ->
    return 0 if !offer_id

    item = getters.current_items.find (item) -> item.offer_id == offer_id
    item?.qty || 0

  pending_review: (state) ->
    state.open.filter (order) -> order.shipping_status in ['collected', 'delivered'] && !order.reviewed_at

  has_provider: (state) -> (provider) ->
    state.current?.provider.toLowerCase() == provider.toLowerCase()

  subtotal_in_cents: (state, getters) ->
    getters.current_items.reduce (total, item) ->
      total += item.total_in_cents
    , 0

  total_in_cents: (state, getters) ->
    getters.subtotal_in_cents +
    state.current?.shipping_price_in_cents +
    state.current?.bag_total_in_cents

  requires_online_payment: (state) ->
    state.current?.status.toLowerCase() == 'pending_payment' &&
    state.current?.provider.toLowerCase() in ['paygate', 'shop2shop', 'moya', 'yoco']
}

actions = {
  restore: ({ commit }) ->
    commit('set_current', read_localstore())

  fetch: ({ commit, dispatch, rootState, state }, id) ->
    commit('loading', true) unless state.polling
    api = api_for(rootState.application)
    api.fetchOrder(id)
    .then (order) ->
      commit('set_current', order)
      order
    .catch (error) ->
      console.error 'orders.fetch:catch', error
    .finally ->
      commit('loading', false)
      commit('timeout_id', clearTimeout(state.timeout_id))
      if state.polling then dispatch('poll')

  fetch_history: ({ commit, rootState, rootGetters, state }, page) ->
    return if not rootGetters['account/logged_in'] || page == null
    commit('loading', true)
    api = api_for(rootState.application)
    api.fetchOrders(rootState.account.current.id, { page: page })
    .then (response) ->
      commit('add_orders', response.orders)
      response.pagination.next_page
    .catch (error) ->
      console.error 'orders.fetch_history:catch', error
    .finally ->
      commit('loading', false)

  fetch_open_orders: ({ commit, dispatch, rootState }) ->
    return unless !!rootState.account.current?.id
    api = api_for(rootState.application)
    api.fetchOpenOrders(rootState.account.current.id)
    .then (orders) -> commit('set_open_orders', orders)
    .catch (error) -> dispatch('account/sign_out', {}, {root: true}) if error.status == 404

  poll: ({ commit, dispatch, state }) ->
    commit 'timeout_id', setTimeout ->
      dispatch('fetch', state.current.id)
    , 10000

  modify: ({ commit, state, rootState, rootGetters }) ->
    commit('loading', true)
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.modifyOrder state.current.id, { body: { modifications: state.modifications } }
    .then (order) ->
      commit('clear_modifications')
      commit('set_current', order)
    .catch (error) ->
      console.error 'Orders.modify:catch', error
    .finally ->
      commit('loading', false)

  cancel_current: ({ rootState, rootGetters, state, commit }) ->
    commit('loading', true)
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.cancelOrder state.current.id
    .then (order) ->
      commit('clear_modifications')
      commit('set_current', order)
    .catch (error) ->
      console.error 'Orders.cancel_current:catch', error
    .finally ->
      commit('loading', false)

  validate_stock_short_items: ({ commit, getters }) ->
    commit('reset_error')
    for item in getters.current_items when (item.qty != item.qty_ordered) and !getters.find_modification(item.id)
      commit('set_error', 'Accept or remove every stock short item, then click UPDATE ORDER')
      commit('invalid_order_item', item.id)
      return false
    true

  validate_modifications: ({ commit, rootGetters, rootState, state }) ->
    commit('loading', true)
    commit('remote_validation', {})
    commit('purge_out_of_stock_new_items')
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    api.validateOrderModifications state.current.id, { body: { modifications: state.modifications } }
    .then (validation) ->
      if validation.out_of_stock || validation.updated || validation.payable_amount_error
        commit('remote_validation', {
          out_of_stock: validation.out_of_stock,
          updated: validation.updated,
          payable_amount_error: validation.payable_amount_error
        })
        commit('reset_modifications', validation.modifications)
        return false
      true
    .catch (error) ->
      console.error 'Orders.validate_modifications:catch', error
    .finally ->
      commit('loading', false)

  validate_new_items: ({ commit, rootState, state, rootGetters }) ->
    commit('loading', true)
    commit('remote_validation', {})
    commit('purge_out_of_stock_new_items')
    api = api_for(rootState.application, rootGetters['suppliers/current'])
    new_items = (mod.item for mod in state.modifications when mod.action == 'add')
    api.validateOfferPicks { body: { items: new_items } }
    .then (validation) ->
      if validation.out_of_stock || validation.updated
        commit('remote_validation', validation)
        commit('reset_add_modifications', validation.items)
        return false
      true
    .catch (error) ->
      console.error 'Orders.validate_new_items:catch', error
    .finally ->
      commit('loading', false)

  add_item: ({ commit, getters }, new_item) ->
    order_item = getters.find_item(new_item.offer.id)
    if order_item?
      console.error 'add_update_modification'
      # @add_update_modification(order_item.id, new_item) # get or create modification, set qty
    else if getters.find_add_modification_by_offer_id(new_item.offer.id)
      commit('update_add_modification', new_item)
    else
      commit('new_modification', { action: 'add', item: new_item })

  post_order_review: ({ commit, rootState }, review) ->
    api = api_for(rootState.application).reviewOrder( review.order_id, { body: { review: review } })
    .then (status) ->
    .catch (error) ->
      console.error 'reviewOrder.catch', error

  resolve_payment_pending: ({ commit, rootState }, order_id) ->
    commit('polling', false)
    commit('loading', true)
    api = api_for(rootState.application).resolvePaymentPending(order_id)
    .then (order) ->
      commit('set_current', order)
    .catch (error) ->
      console.error 'resolve_payment_pending.catch', error
    .finally () ->
      commit('loading', false)
      commit('polling', true)

  reorder: ({ state, dispatch, rootState }) ->
    offer_ids = (item.offer_id for item in state.current?.order_items)
    order_id = state.current.id
    api = api_for(rootState.application).fetchOrderOffers(order_id, { offer_ids: offer_ids })
    .then (offers) ->
      cart_items = (build_cart_item(order_item, offers) for order_item in state.current?.order_items).filter (item) -> item.offer != undefined
      dispatch('suppliers/fetch', null, { root: true })
        .then -> dispatch('suppliers/select', state.current.supplier_id, { root: true })
        .then -> dispatch('cart/reset', cart_items, { root: true })
    .catch (error) ->
      console.error error

  register_transaction: ({ rootGetters, rootState }, config) ->
    return console.error('register_transaction: no order_id') unless config.order_id
    return console.error('register_transaction: no supplier') unless config.supplier

    api = api_for(rootState.application, config.supplier)
    api.registerTransaction(config.order_id)
    .then (response) -> response
    .catch (response) ->
      console.error 'api.registerTransaction.catch:', response

  process_yoco_transaction: ({ rootState }, config) ->
    return console.error('process_yoco_transaction: no order_id') unless config.order_id
    return console.error('process_yoco_transaction: no id') unless config.id
    return console.error('process_yoco_transaction: no token') unless config.token
    return console.error('process_yoco_transaction: no supplier') unless config.supplier

    api = api_for(rootState.application, config.supplier)
    api.processYocoTransaction config.order_id, config.id, config.token
    .then (response) -> response
    .catch (response) ->
      console.error 'api.processYocoTransaction.catch:', response

  fetch_transaction: ({ rootGetters, rootState }, config) ->
    return console.error('fetch_transaction: no id') unless config.id
    return console.error('fetch_transaction: no supplier') unless config.supplier

    api = api_for(rootState.application, config.supplier)
    api.fetchTransaction(config.id)
    .then (response) -> response
    .catch (error) ->
      console.error 'agency_api.fetchTransaction catch', error
}

build_cart_item = (order_item, offers) ->
  {
    offer: offers.find((offer) -> offer.id == order_item.offer_id),
    qty: order_item.qty,
    formatted_qty: order_item.formatted_qty
  }

mutations = {
  flush_history: (state) ->
    state.history = []

  flush_current: (state) ->
    state.current = null
    state.modifications = []

  invalid_order_item: (state, id) ->
    state.invalid_order_item = id

  loading: (state, loading) ->
    state.loading = loading

  clear_modifications: (state) ->
    state.modifications = []

  new_modification: (state, mod) ->
    if mod.action == 'add'
      state.modifications.push(mod)
    else
      existing_mod = state.modifications.find (m) -> m.order_item_id == mod.order_item_id
      if existing_mod? then existing_mod.action = mod.action else state.modifications.push(mod)

  add_orders: (state, orders) ->
    state.history = state.history.concat(orders)

  reset_modifications: (state, modifications = []) ->
    state.modifications = modifications

  reset_add_modifications: (state, items) ->
    mods = state.modifications.filter (mod) -> mod.action != 'add'
    mods.push({ action: 'add', item: item }) for item in items
    state.modifications = mods

  update_add_modification: (state, order_item) ->
    # add_mods = _.where(state.modifications, { action: 'add' })
    # modification = add_mods.find (mod) -> mod.item.offer.id == order_item.offer.id
    modification = state.modifications.find (mod) -> mod.item.offer.id == order_item.offer.id
    modification.item.qty = order_item.qty

  polling: (state, polling) ->
    state.polling = polling

  purge_out_of_stock_new_items: (state) ->
    state.modifications = state.modifications.filter (mod) ->
      return true if mod.action != 'add'
      return !mod.item.offer.out_of_stock_at

  set_current: (state, order) ->
    state.current = order

  remote_validation: (state, validation) ->
    state.remote_validation = validation

  reset_error: (state) ->
    state.error = null

  set_error: (state, error) ->
    state.error = error

  set_history: (state, orders) ->
    state.history = orders

  set_open_orders: (state, orders) ->
    state.open = orders

  timeout_id: (state, timeout_id) ->
    state.timeout_id = timeout_id

  restore: (state) ->
    cached = store.read()
    if cached and cached.current
      state.current = cached.current
    else
      state = defaults()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

build_order_item = (item, modification, order_stock_short) ->
  order_item = _.clone(item)
  order_item.sellable = as_json(item.sellable)
  if modification
    switch modification.action
      when 'update'
        order_item.qty = order_item.qty_ordered = modification.qty
        order_item.formatted_qty = order_item.formatted_qty_ordered = if order_item.sellable.per_kg then "#{order_item.qty}kg" else order_item.qty
        order_item.total_in_cents = order_item.unit_price_in_cents * parseFloat(order_item.qty)
      when 'accept'
        order_item.modified_qty = order_item.qty
        order_item.modified_formatted_qty_ordered = if order_item.sellable.per_kg then "#{order_item.qty}kg" else order_item.qty
        order_item.total_in_cents = order_item.unit_price_in_cents * parseFloat(order_item.modified_qty)
      when 'remove'
        order_item.modified_qty = 0
        order_item.modified_formatted_qty_ordered = if order_item.sellable.per_kg then "0kg" else 0
        order_item.total_in_cents = order_item.unit_price_in_cents * parseFloat(order_item.modified_qty)
    order_item.modification = modification
  # order_item.stock_short = order_stock_short and order_item.qty != order_item.qty_ordered
  order_item

build_new_order_item = (modification) ->
  item = {}
  item.sellable = modification.item.offer.sellable
  item.name = item.sellable.name
  item.image_url = item.sellable.image_url
  item.qty = item.qty_ordered = parseFloat(modification.item.qty)
  item.formatted_qty = item.formatted_qty_ordered = modification.item.formatted_qty
  item.modification = modification
  item.unit_price_in_cents = modification.item.offer.price_in_cents
  item.total_in_cents = modification.item.qty * modification.item.offer.price_in_cents
  item.offer_id = modification.item.offer.id
  item.out_of_stock_at = modification.item.offer.out_of_stock_at
  item.updated = modification.item.offer.updated
  item
