
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
  computed: {
    account: ->
      @$store.state.account.current

    agency_mode: ->
      true

    debtorcards_feature: ->
      @$store.getters['application/debtorcards_enabled']

    facebook_url: ->
      @$store.getters['application/facebook_url']

    facebook_share_url: ->
      process.env.VUE_APP_URL

    whatsapp_share_url: ->
      name = @$store.getters['application/name']
      "https://api.whatsapp.com/send?text=Check out #{name} online: #{process.env.VUE_APP_URL}"

    support_link: ->
      formatted_phone = parsePhoneNumber("+#{process.env.VUE_APP_SUPPORT_PHONE}")
      "https://wa.me/#{formatted_phone.number.e164}?text=I require assistance with: #{@$store.getters['application/name']}"

    env_development: ->
      process.env.NODE_ENV == 'development'

    logged_in: ->
      @$store.getters['account/logged_in']

    logo_image_url: ->
      @$store.getters['application/logo_image_url']

    marketplace: ->
      !@$store.getters['application/is_agency']

    sharable: ->
      false

    not_datafree: ->
      !JSON.parse(window.localStorage.getItem('moya-discover'))

    visible: {
      get: -> @$store.state.layout.sidepanel
      set: (v) -> @$store.commit('layout/sidepanel', v)
    }
  }

  methods: {
    logout: ->
      @$store.dispatch('account/sign_out')
        .then =>
          @$router.replace('/') if !!@$route.meta.authenticate
          @$store.dispatch('layout/notify', @$t('popup_account_sign_out'))
      @visible = false
  }
}
